import { Injectable } from '@angular/core';
import { RestService } from 'src/app/services/merchant/Rest.service';
import { Groups } from '../models/groups.model';
import { Operator } from '../models/operator.model';
import { AuthService } from '../auth/auth.service';
import { FirmwareUpload } from '../models/firmwareUpload.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { GetFile } from '../models/getFile';
import { LEVEL_ROL } from '../util/constants';
import { Rol } from '../models/rol.model';
import { Client, SubClient } from '../models/client.model';
import { RolService } from '../pages/admin/rol/rol.service';
import { OperatorService } from '../pages/admin/operator/operator.service';
import { FilterAndPagBean } from '../models/FilterAndPagBean';


/**
 * @deprecated
 * Este servicio debería reemplazarse por los helpers de rol y jerarquía.
 */
@Injectable({
  providedIn: 'root',
})
export class UtilService {

  private operators$: BehaviorSubject<any> = new BehaviorSubject([]);
  private clients$: BehaviorSubject<any> = new BehaviorSubject([]);
  private rols$: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private merchantRest: RestService, 
    private authService:AuthService,
    private rolService:RolService,
    private operatorService:OperatorService
  ) {}

  async sendFile(_body:FirmwareUpload){
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'setFileToSend/',true,true )   
    .catch(err => {
      return null
    })

    return value;      
  }

  async get(id: number) {
    let _body: GetFile = new GetFile();
    _body.id = id

    let value = await this.merchantRest.commonRestCallConsolaRest(_body, 'get/', true, true)
      .catch(err => {
        return null
      })

    return value;
  }

  async setGetFileAtento(file: GetFile) {
    let _body = file
    let value = await this.merchantRest.commonRestCallConsolaRest(_body, 'setGetFileAtento/', true, true)
    .catch(err => {
      return null
    })

    return value;
  }

  async findGroups(){
    let _body: Groups = new Groups();
    let userSession = this.authService.currentUserValue();
    if (userSession?.entorno) {
        _body.idEntorno = userSession.entorno.id;
    }
    if (userSession?.operator) {
        _body.idOperator = userSession.operator.id;
    }
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getListGroup/',true,true )   
    .catch(err => {
      return null
    })

    return value!.data;  
  }
    
    
  async updateFileMachine(_body:FirmwareUpload){
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'setExcludeMachineFirmware/',true,true )   
    .catch(err => {
      return null
    })

    return value;      
  }

  public getRolsAndOperators(){
    this.getRols();
    this.getOperators();
  }
  
  private async getRols(): Promise<void> {
    const _request = new FilterAndPagBean(null,null, [], null, null, null);
    const response = await this.rolService.find(_request);
    if (response && response.data) {
      this.rols$.next(response.data.sort((a:Rol,b:Rol)=>{ return (a.id as number) - (b.id as number) }));
    }
  }

  private async getOperators(): Promise<void> {
    const _request = new FilterAndPagBean(null,null, [], null, null, null);
    const response = await this.operatorService.find(_request);
    if (response && response.data) {
      this.operators$.next(response.data.sort((a:Operator,b:Operator)=>{ return a.name.localeCompare(b.name) }));
    }
  }

  public cleanRolsAndOperators(){
    this.rols$.next([]);
    this.operators$.next([]);
  }

  public findRol(): Observable<any> {
    return this.rols$;
  }

  public findOperators(): Observable<any> {
    return this.operators$;
  }

  public findClients(): Observable<any> {
    return this.clients$;
  }

  public isAdmin(): boolean {
    const userSession = this.authService.currentUserValue();
    return userSession?.rol !== undefined && userSession.rol.id === LEVEL_ROL.ADMIN;
  }

  public isOperator(): boolean {
    const idCli = this.getClientId();
    return idCli === undefined;
  }

  public getOperatorId(): number | undefined {
    const userSession = this.authService.currentUserValue();
    return userSession.operator?.id;
  }

  public getOperator(): Operator | undefined {
    const userSession = this.authService.currentUserValue();
    return userSession.operator;
  }

  public getRolId(): number | undefined {
    const userSession = this.authService.currentUserValue();
    return userSession.rol?.id;
  }

  public getRol(): Rol | undefined {
    const userSession = this.authService.currentUserValue();
    return userSession.rol;
  }

  public getClientId(): number | undefined {
    const userSession = this.authService.currentUserValue();
    return userSession.client?.id;
  }

  public getClient(): Client | undefined {
    const userSession = this.authService.currentUserValue();
    return userSession.client;
  }

  public getSubClientId(): number | undefined {
    const userSession = this.authService.currentUserValue();
    return userSession.subClient?.id;
  }

  public getSubClient(): SubClient | undefined {
    const userSession = this.authService.currentUserValue();
    return userSession.subClient;
  }
}